$search-plp-top-banner-background: color(organism-background);
$search-plp-top-banner-color: color(light);
$search-plp-top-banner-category-name-display: inline-block;
$search-plp-top-banner-category-name-font: #{rem-calc(15)} / 1.33 $font-secondary;
$search-plp-top-banner-results-count-display: inline-block;
$search-plp-top-banner-category-name-with-category-cover-display: none;
$search-plp-top-banner-category-cover-display: block;
$search-plp-top-banner-results-count-font: $search-plp-top-banner-category-name-font;
$search-plp-top-banner-category-name-text-transform: none;
$search-plp-top-banner-results-count-text-transform: none;
$search-plp-top-banner-text-align: left;
$search-plp-top-banner-category-name-margin: rem-calc(17 0 2 20);

@import "@lora/05-components/search/search-plp-top-banner";