$search-refinements-border-top: none;
$search-refinements-category-name-padding: rem-calc(10 50 0 20);
$search-refinements-category-name-margin: rem-calc(0 0 35);
$search-refinements-category-name-separator: none;
$search-refinements-category-name-font: 500 #{rem-calc(12)} / 1.15 $font-primary;
$search-refinements-header-padding--large: rem-calc(14 40 14 20);
$search-refinements-header-margin--large: rem-calc(0 0 30);
$search-refinements-header-title-font: normal #{rem-calc(14)} / 1.15 $font-primary;
$search-refinements-header-title-font--large: $search-refinements-category-name-font;
$search-refinements-header-border-top--large: rem-calc(1) solid color(border);
$search-refinements-category-name-border-top: rem-calc(1) solid color(border);
$search-refinements-header-back-and-results-text-transform: none;
$search-refinements-header-back-and-results-font: #{rem-calc(12)} / 1.5 $font-primary;
$search-refinements-footer-margin: rem-calc(0 0 10);
$search-refinements-header-title-text-transform: none;
$search-refinements-header-title-text-transform--large: $search-refinements-header-title-text-transform;
$search-refinements-header-title-font--large: #{rem-calc(18)} / rem-calc(20) $font-secondary;

@import "@lora/05-components/search/search-refinements";